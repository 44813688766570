import { DOCUMENT } from '@angular/common';
import {
  DestroyRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  TransferState,
  ViewContainerRef,
  inject,
  makeStateKey,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlatformService } from '@rma/generic/util/environment';
import { objectDistinctUntilChanged } from '@rma/generic/util/operators/combined';
import { Observable, combineLatest, isObservable, map } from 'rxjs';
import { RmaLetData, createRmaLetData } from '../util-create-rma-let-data/createRmaLetData';

type ContextError = string | null | undefined;

class LetContext<T> {
  public $implicit = null;
  constructor(
    public readonly rmaLet: T | null,
    public readonly $error: ContextError,
    public readonly $complete: boolean,
  ) {}
}

class CompleteContext<T> {
  public $implicit = null;
  constructor(public readonly rmaLet: T | null) {}
}

class SuspenseContext {
  $implicit = null;
  constructor(public readonly value: number) {}
}

class ErrorContext {
  $implicit = null;
  constructor(public readonly error: string) {}
}

enum ViewActionType {
  Suspense = 'suspense',
  Loaded = 'Loaded',
  Complete = 'Complete',
  Error = 'Error',
}

interface ViewActionData<T> {
  result: T | null;
  error: string | null | undefined;
  complete: boolean;
}

interface ViewAction<T> {
  type: ViewActionType;
  data: ViewActionData<T>;
}

@UntilDestroy()
@Directive({
  selector: '[rmaLet]',
  standalone: true,
})
export class LetDirective<T> implements OnInit {
  @Input({ required: true })
  public rmaLet: Observable<T> | RmaLetData<T>;

  @Input()
  set rmaLetSuspenseRef(template: TemplateRef<SuspenseContext>) {
    this.suspenseRef = template;

    if (this.platformService.isPlatformBrowser && !this.initialLoad) {
      this.showTemplate({ type: ViewActionType.Suspense, data: { result: null, error: null, complete: false } });
    }
  }

  @Input('rmaLetErrorRef')
  public errorRef: TemplateRef<ErrorContext>;

  @Input('rmaLetCompleteRef')
  public completeRef: TemplateRef<CompleteContext<T>>;

  private static nextId = 0;
  private readonly stateKey = makeStateKey<boolean>(`rmaLet${LetDirective.nextId++}`);
  private readonly destroyRef = inject(DestroyRef);
  // This input can load faster than the suspense template is added.
  private initialLoad = false;

  private suspenseRef: TemplateRef<SuspenseContext>;

  public constructor(
    private readonly vcRef: ViewContainerRef,
    private readonly elementRef: ElementRef,
    private readonly templateRef: TemplateRef<LetContext<T>>,
    private readonly platformService: PlatformService,
    private readonly transferState: TransferState,
    @Inject(DOCUMENT) private readonly doc: Document,
  ) {}

  public ngOnInit(): void {
    if (!this.rmaLet) {
      console.error(`No data passed to rmaLet ${this.doc.location} ${this.elementRef.nativeElement}`);
    }
    this.setData(this.rmaLet);
  }

  private showTemplate(action: ViewAction<T>) {
    if (this.platformService.isPlatformServer) {
      this.transferState.set(this.stateKey, true);
    } else if (this.transferState.hasKey(this.stateKey) && this.transferState.get(this.stateKey, false)) {
      this.transferState.set(this.stateKey, false);
      return;
    }

    this.initialLoad = true;
    switch (action.type) {
      case ViewActionType.Suspense:
        this.showSuspense(action.data);
        break;
      case ViewActionType.Complete:
        this.showComplete(action.data);
        break;
      case ViewActionType.Error:
        this.showError(action.data);
        break;
      default:
        this.showLoaded(action.data);
    }
  }

  private showLoaded(data: ViewActionData<T>): void {
    const context = new LetContext(data.result, data.error, data.complete);
    this.embedTemplate(this.templateRef, context);
  }

  private showSuspense(data: ViewActionData<T>): void {
    const context = new SuspenseContext(3);
    this.embedCustomOrDefaultTemplate(this.suspenseRef, context, data);
  }

  private showComplete(data: ViewActionData<T>): void {
    const context = new CompleteContext(data.result);
    this.embedCustomOrDefaultTemplate(this.completeRef, context, data);
  }

  private showError(data: ViewActionData<T>): void {
    const context = new ErrorContext(data.error ?? '');
    this.embedCustomOrDefaultTemplate(this.errorRef, context, data);
  }

  private embedCustomOrDefaultTemplate<U>(ref: TemplateRef<U>, context: U, data: ViewActionData<T>) {
    if (ref) {
      this.embedTemplate(ref, context);
    } else {
      const letContext = new LetContext(data.result, data.error, data.complete);
      this.embedTemplate(this.templateRef, letContext);
    }
  }

  private embedTemplate<U>(suspenseTemplate: TemplateRef<U>, context: U) {
    this.vcRef.clear();
    const ref = this.vcRef.createEmbeddedView(suspenseTemplate, context);
    ref.markForCheck();
  }

  private mapToViewAction(complete$: boolean, error$: ContextError, loading$: boolean, result$: T | null): ViewAction<T> {
    const type = this.getViewActionType(loading$, complete$, error$);
    const data: ViewActionData<T> = { result: result$, error: error$, complete: complete$ };

    return { type, data };
  }

  private getViewActionType(loading: boolean, complete: boolean, error: string | null | undefined): ViewActionType {
    if (loading) {
      return ViewActionType.Suspense;
    }
    if (error) {
      return ViewActionType.Error;
    }
    if (complete) {
      return ViewActionType.Complete;
    }
    return ViewActionType.Loaded;
  }

  private setData(value: Observable<T> | RmaLetData<T>) {
    const data = isObservable(value) ? createRmaLetData(value, this.destroyRef) : value;
    combineLatest(data)
      .pipe(
        map(({ complete$, error$, loading$, result$ }) => this.mapToViewAction(complete$, error$, loading$, result$)),
        objectDistinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((action: ViewAction<T>) => this.showTemplate(action));
  }
}
